import * as React from "react";

import {
  PageMeta,
  SecondaryPageTitle,
  SeriousTalk,
} from "../components/shared/secondary-page-elements";

import DownloadModal from "../components/shared/download-modal";
import Footer from "../components/sections/footer";
import Grid from "../components/shared/grid";
import { H5 } from "../components/shared/headings";
import Layout from "../components/shared/layout";
import { Link } from "gatsby";
import Navbar from "../components/navbar";
import Section from "../components/shared/section";
import Seo from "../components/shared/seo";

const TLDRPage = () => {
  return (
    <Layout>
      <Seo title={"Updates to our Privacy Policy and Terms | Copilot"} />
      <DownloadModal />
      <Navbar />
      <Section>
        <Grid>
          <SecondaryPageTitle>
            Updates to our Privacy Policy and Terms
          </SecondaryPageTitle>
          <PageMeta>
            <H5>September 15, 2021</H5>
          </PageMeta>
          <SeriousTalk>
            <p>We're always looking for ways to improve the user experience, especially as we grow and add new features. With this in mind, we are updating our Privacy Policy and Terms of Service to provide updates and clarifications regarding the ways we collect, process, and use information as well as the terms under which you use our service. These changes will go into effect on October 15, 2021 unless you agree to them before then.</p>
            <p>We encourage you to read the revised documents in their entirety, but here are some highlights of what's changing.</p>
            <p><Link to="/privacy-policy">Privacy Policy</Link>: Our updated Privacy Policy more clearly describes the types of data we collect, how we collect it and use it, and with whom we share it. For example, among other things, we've further defined the types of financial information we receive when you connect your registered Copilot account to financial institutions and other third-party data sources or otherwise provide us with financial information from these financial data sources.  The Privacy Policy also outlines our interactions with other partners and service providers, explaining how they may collect and use data, while clarifying that we do not share your financial information with third parties for joint marketing purposes or their direct marketing purposes.</p>
            <p>We are excited about the new features and integrations we've been working on. The Privacy Policy updates account for these new functionalities and expanded services. It also describes the choices and rights you have with regard to Copilot marketing communications and control over your account information. The Privacy Policy details Copilot's security safeguards and data retention policies. You can read more about Copilot's privacy and security <Link to="/privacy-and-security">here</Link>, including our use of the Google Cloud Platform for our data infrastructure and the variety of measures we employ to keep your data private and safe.</p>
            <p><Link to="/terms-of-service">Terms of Service</Link>: We added language to our Terms of Service to account for new integrations and features that allow users to connect their Copilot account with new partners and monitor market data.</p>
            <p>It's important to us that we keep your data safe and increase transparency about how we use it. By continuing to use our services on or after October 15, 2021, you acknowledge and agree to our updated Terms of Service and Privacy Policy. Please feel free to contact us via <a href="mailto:privacy@copilot.money" target="_blank" rel="noopener noreferrer">privacy@copilot.money</a> if you have any questions or concerns.</p>
            <p>Thanks,<br/>Your Copilot Team</p>
          </SeriousTalk>
        </Grid>
      </Section>
      <Footer />
    </Layout>
  );
};

export default TLDRPage;
